import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page, QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import { getAllObjectives, getAllTeams } from '../../api/objectives'
import Navbar from '../../components/Navbar/Navbar'
import TeamCell from './TeamCell'

function InnerManagerView({headers, teams}: any) {
	return (
		<div className='TabHeaders'>
			<Navbar />

			<QuickTable
				data={teams}
				headers={headers as any}
				primaryKey='id'
				hover
				rowStyle={()=>({
					borderTop: '1px solid var(--borderColor)',
					borderRadius: 0,
				})}
			/>
		</div>
	)
}

export default function ManagerView() {
	const [headers, setHeaders] = useState<QuickTableHeaders>([] as any)
	const [teams, setTeams] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const fetchData = async ()=>{
		await getAllObjectives().then((data: any) => {
			let newHeaders: QuickTableHeaders = {
				name: { title: '', width: 300, component: ({ row, cell }: any) => <Link to={`/Team/${row.id}`}>{cell}</Link> },
			}

			data.forEach((r: any) => {
				newHeaders[`ID${r.id}`] = {
					title: `${r.shortName}`,
					width: 250,
					props: { objectiveId: r.id, objectiveName: r.name },
					component: TeamCell,
					headerStyle: { textAlign: 'center' },
					cellStyle: () => ({ textAlign: 'center' })
				}
			})

			setHeaders(newHeaders)
		})

		await getAllTeams().then(newTeams => {
			setTeams(newTeams)
		})

		setIsLoading(false)
	}

	useEffect(() => {
		fetchData()
	}, [])

	if(isLoading){
		return <Page loading/>
	}

	return (
		<InnerManagerView teams={teams} headers={headers}/>
	)
}
