import { collection, doc, getDoc, getDocs, getFirestore, setDoc } from "firebase/firestore";

//For anyone reading this clusterF*** of a file, just remember I only had 2 days!

const unwrap = (firebaseDoc:any) => {
	return {
		id: firebaseDoc.id,
		...firebaseDoc.data()
	}
}


export const getAllObjectives = async () => {
	const db = getFirestore()
	const ref = await getDocs(collection(db, 'Objectives'))

	return ref.docs.map(unwrap)
}

export const getObjectiveById = async (id:string) => {
	const db = getFirestore();
	const ref = doc(collection(db, 'Objectives'), id)

	return unwrap(await getDoc(ref))
}

export const getAllTeams = async () => {
	const db = getFirestore()
	const ref = await getDocs(collection(db, 'Teams'))

	return ref.docs.map(unwrap)
}

export const getTeamById = async (id: string) => {
	const db = getFirestore();
	const ref = doc(collection(db, 'Teams'), id)
	const team =  unwrap(await getDoc(ref))
	const allTeams = await getAllTeams()

	team.objectives = await Promise.all(team.objectives.map(async (b:any)=>{
		let obj = await getObjectiveById(b.id)

		obj = {
			relatedTeams: allTeams.filter(t => t.id !== id && t.objectives.find((j:any)=>j.id === obj.id)),
			description: team.objectives.find((b:any)=>b.id === obj.id).description,
			...obj
		}

		return obj
	}))

	const allObjectives = await getAllObjectives()

	team.unusedObjectives = allObjectives.filter((b:any)=>!team.objectives.find((tb:any)=>tb.id===b.id))

	return team
}

export const updateObjectiveDescription = async (teamId: string, objectiveId: string, description:string) => {
	const db = getFirestore();
	const ref = doc(collection(db, 'Teams'), teamId)
	const team = unwrap(await getDoc(ref))
	const idx = team.objectives.findIndex((b:any) => b.id === objectiveId)

	team.objectives[idx].description = description

	const teamsCollection = collection(db, 'Teams')
	await setDoc(doc(teamsCollection, teamId), team)
}

export const addObjectiveToTeam = async (teamId:string, objectiveId:string) => {
	const db = getFirestore();
	const ref = doc(collection(db, 'Teams'), teamId)
	const team = unwrap(await getDoc(ref))

	const exists = team.objectives.find((b: any) => b.id === objectiveId)

	if(exists){
		return
	}

	team.objectives.push({
		id: objectiveId,
		description: ''
	})

	const teamsCollection = collection(db, 'Teams')
	await setDoc(doc(teamsCollection, teamId), team)
}

export const removeObjectiveFromTeam = async (teamId: string, objectiveId: string) => {
	const db = getFirestore();
	const ref = doc(collection(db, 'Teams'), teamId)
	const team = unwrap(await getDoc(ref))
	const newArray = team.objectives.filter((b: any) => b.id !== objectiveId)

	team.objectives = newArray

	const teamsCollection = collection(db, 'Teams')
	await setDoc(doc(teamsCollection, teamId), team)
}