import { Route, BrowserRouter, Switch } from 'react-router-dom'
import React from 'react'
import ManagerView from './pages/ManagerView/ManagerView';
import TeamView from './pages/TeamView/TeamView';
import PopupProvider from './components/Popup/PopupProvider';


function App() {
  return (
    <PopupProvider>
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path={'/Team/:teamId'} component={TeamView} />
            <Route path={'/'} component={ManagerView} />
          </Switch>
        </BrowserRouter>
      </div>
    </PopupProvider>
  );
}

export default App;
