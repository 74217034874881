import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { ConfirmButton } from 'react-ui-scaffold'
import { removeObjectiveFromTeam, updateObjectiveDescription } from '../../api/objectives'
import RelatedTeam from './RelatedTeam'
import './TeamView.css'

export default function ObjectiveCard({objective, team, refresh}:any) {
	const [desc, setDesc] = useState(objective.description)

	const changeHandler = (e:any)=>setDesc(e.target.value)

	const blurHandler = (e:any)=>updateObjectiveDescription(team.id, objective.id, e.target.value)

	const remove = async ()=>removeObjectiveFromTeam(team.id, objective.id).then(refresh)

	return (
		<div className='ObjectiveCard'>
			<span className="deleteButton">
				<ConfirmButton
					className='plain'
					content={<FontAwesomeIcon icon={faTimes}/>}
					expandedContent={'Delete'}
					onClick={remove}
				/>
			</span>

			<div className="title">
				{objective.name}
			</div>

			<div className="desc">
				<textarea
					className="input wide"
					value={desc}
					onChange={changeHandler}
					onBlur={blurHandler}
				/>
			</div>

			<div className="list">
				<div className="listTitle">
					Other teams working on this:
				</div>

				{objective.relatedTeams.map((t: any) => <RelatedTeam team={t} key={t.id} objective={objective} />)}
			</div>
		</div>
	)
}
