import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import logo from '../../assets/logo-white.svg'

interface Props{
	children?:any,
	title?: any
}

export default function Navbar({children, title}:Props) {
	return (
		<div className='Navbar flexY'>
			<div className="title flexY">
				<Link style={{paddingRight: '30px', transform: 'translateY(3px)'}} to='/'>
					<img src={logo} alt='Clio'/>
				</Link>

				{title}
			</div>
			<div className="children">
				{children}
			</div>
		</div>
	)
}
