import React, { useEffect, useState } from 'react'
import { Page } from 'react-ui-scaffold'
import { getTeamById } from '../../api/objectives'
import Navbar from '../../components/Navbar/Navbar'
import ObjectiveCard from './ObjectiveCard'
import UnusedObjective from './UnusedObjective'

function InnerTeamView({team, refresh}:any) {
	console.log(team)
	return (
		<div className='TeamView'>
			<Navbar title={team.name} />

			<div className="box">
				{team.objectives.map((obj: any) => <ObjectiveCard objective={obj} key={obj.id} team={team} refresh={refresh}/>)}
			</div>

			<div className="uabox">
				{team.unusedObjectives.map((r:any)=>{
					return <UnusedObjective team={team} objective={r} refresh={refresh}/>
				})}
			</div>
		</div>
	)
}

export default function TeamView({ match }:any) {
	const [team, setTeam] = useState<any>()

	const fetchTeam = async ()=>{
		const newTeam = await getTeamById(match.params.teamId)
		console.log(newTeam)
		setTeam(newTeam)
	}

	useEffect(()=>{
		fetchTeam()
	}, [match.params.teamId]) //eslint-disable-line

	if(!team){
		return <Page loading/>
	}

	return (<>
		{team && <InnerTeamView team={team} refresh={fetchTeam}/>}
	</>

	)
}
