import React, { useMemo, useState } from 'react'
import RelatedTeamPopup from './RelatedTeamPopup'

export default function RelatedTeam({team, objective}:any) {
	const [visible, setVisible] = useState(false)

	const desc = useMemo(() => {
		const teamObj = team.objectives.find((j: any) => j.id === objective.id)
		return teamObj.description
	}, [team.id, objective.id]) //eslint-disable-line

	const descString = desc.length < 50 ? desc : `${desc.substring(0, 50)}...`

	return (
		<div className='RelatedTeam'>
			<RelatedTeamPopup objective={objective} team={team} visible={visible} setVisible={setVisible} desc={desc}/>
			<span onClick={()=>setVisible(true)} style={{cursor: 'pointer'}} className='clicky'>
				{team.name} - <span className="description">"{descString}"</span>
			</span>

		</div>
	)
}
