import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { addObjectiveToTeam } from '../../api/objectives'

interface Props{
	objective: any,
	team: any,
	refresh: any,
}

export default function UnusedObjective({team, objective, refresh}:Props) {
	const add = async ()=>{
		await addObjectiveToTeam(team.id, objective.id)
		refresh()
	}

	return (
		<div className='UnusedObjective'>
			<button className="button plain" onClick={add}>
				<span className="controls">
					<FontAwesomeIcon icon={faPlusCircle} />
				</span>

				{objective.name}
			</button>
		</div>
	)
}
