import Popup from '../../components/Popup/Popup'

export default function RelatedTeamPopup({team, desc, visible, setVisible}:any) {


	return (
		<Popup visible={visible} setVisible={setVisible} title={`${team.name}`}>
			{desc}
		</Popup>
	)
}
