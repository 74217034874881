import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBGXGtZQdxNEuhibGcjS1teh2fvatY4RE8",
  authDomain: "hack-align.firebaseapp.com",
  projectId: "hack-align",
  storageBucket: "hack-align.appspot.com",
  messagingSenderId: "622388195771",
  appId: "1:622388195771:web:0c9aee77507690eb6b949e",
  measurementId: "G-RG67Y0782R"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)