import React from 'react'
import Popup from '../../components/Popup/Popup'

interface Props{
	visible: boolean,
	setVisible(v:boolean):void,
	teamName:string,
	objectiveName:string,
	description:string
}

export default function TeamPopup({visible, setVisible, teamName, objectiveName, description}:Props) {
	return (
		<Popup visible={visible} setVisible={setVisible} title={objectiveName}>
			<div style={{padding: '0 30px', paddingBottom: '30px'}}>
				<h3>{teamName}</h3>
				{description}
			</div>
		</Popup>
	)
}
