import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import TeamPopup from './TeamPopup'

export default function TeamCell({row, objectiveId, objectiveName}:QuickTableCellProps) {
	const matched = row?.objectives?.find((c:any)=>c.id === objectiveId)
	const [visible, setVisible] = useState(false)

	return (
		<span className="TeamCell">
			<TeamPopup
				visible={visible}
				setVisible={setVisible}
				teamName={row.name}
				description={matched?.description}
				objectiveName={objectiveName}
			/>
			{matched && <button className='button plain' style={{cursor: 'pointer', color: 'var(--positiveAccentColor)'}} onClick={()=>setVisible(true)}>
				<FontAwesomeIcon icon={faCheckCircle} />
			</button>}
		</span>
	)
}
